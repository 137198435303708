<template>
  <div class="terminal-detail-page">
    <Header type="add" path="/bill/terminalAdd" class="header" />
    <Card :info="info" :clickable="false" :map="map" mapField="fundType"></Card>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import Card from './components/detailCard.vue'

export default {
  components: {
    Header,
    Card
  },
  data() {
    return {
      info: {},
      map: {
        '1': '欠款',
        '2': '还款'
      },
    }
  },
  activated() {
    console.log(this.$route.query.info)
    if (this.$route.query.info) {
      this.info = this.$route.query.info
    }
  }
}
</script>

<style lang="scss" scoped>
.terminal-detail-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  .header {
    margin-bottom: 12px;
  }
}
</style>