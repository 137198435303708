<template>
  <div class="growers-add-page">
    <Header type="add" />
    <Form ref="formA" :rows="rowsFirst" v-model="formFirst" />
    <div class="label-value-container">
      <div class="label-value-wrap">
        <div class="label">地址</div>
        <Field placeholder="请输入地址" v-model="address" />
      </div>
    </div>
    <div class="title">账款信息</div>
    <Form ref="formB" :rows="rowsSecond" v-model="formSecond" />
    <div class="upload-wrap">
      <ImgUpload label="凭证拍照" :required="true" v-model="images" />
    </div>
    <CustomTextarea label="备注" v-model="remark"  placeholder="不要搞错了…" />
    <div class="btn-wrap">
      <Button size="full" @click="submit">提交</Button>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/headerWithNavigation/index.vue'
import Form from '@/components/common/form/index.vue'
import { Field } from 'vant'
import ImgUpload from '@/components/common/imgUploadCard/index.vue'
import Button from '@/components/common/button/CustomButton.vue'
import { submitBigGrowerBill } from '@/api/bill'
import dayjs from 'dayjs'
import CustomTextarea from '@/components/common/textArea/index.vue'
import { getDealerId } from '@/utils/user'

export default {
  components: {
    Header,
    Form,
    Field,
    ImgUpload,
    Button,
    CustomTextarea,
  },
  data() {
    return {
      rowsFirst: [
        {label: '终端联系人', key: 'contact', type: 'input'},
        {label: '联系电话', key: 'phone', type: 'input', maxlength: 11},
      ],
      rowsSecond: [
        {label: '日期', key: 'date', type: 'date', required: true},
        {label: '账目类型', key: 'type', type: 'radio', required: true, data: [
          { key: "欠款", value: "欠款" },
          { key: "还款", value: "还款" },
        ]},
        {label: '金额/元', key: 'money', type: 'input', required: true},
      ],
      images: [],
      formFirst: {},
      formSecond: {},
      bigfarmersId: '',
      bigfarmersType: '',
      dealerId: '',
      remark: '',
      address: '' // 没用到的参数
    }
  },
  activated() {
    this.bigfarmersId = this.$route.query.bigfarmersId || ''
    this.bigfarmersType = this.$route.query.bigfarmersType || ''
    this.formSecond = {
      date: new Date(),
      type: '欠款'
    };
    this.formFirst = {
      date: new Date(),
      contact: this.$route.query.contactPerson || '',
      phone: this.$route.query.contactTel || '',
    }
    this.images = '';
    this.dealerId = '';
    this.remark = '';
    this.address = this.$route.query.address || '' // 没用到的参数
  },
  methods: {
    async submit() {
      if (!this.$refs.formA.validate() || !this.$refs.formB.validate()) {
        return
      }
      if (this.images.length === 0) {
        this.$toast('请上传图片')
        return
      }
      try {
        await submitBigGrowerBill({
          "bigfarmersId": this.bigfarmersId || "62b97bd5bb734c549c42a8b50212422f",
          "bigfarmersType": this.bigfarmersType || "1",
          "fundDate": dayjs(this.formSecond.date).format('YYYY-MM-DD'),
          "fundType": this.formSecond.type === '欠款' ? 1 : 2,
          "fundNum": this.formSecond.money,
          "fundPhoto": this.images.join(','),
          "remark": this.remark,
          "dealerId": getDealerId(),
        })
        this.$toast("提交成功");
        setTimeout(()=>{
          this.$router.back()
        }, 1000)
      }
      catch (err) {
        this.$toast(err.message);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.growers-add-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  text-align: left;
  padding-bottom: 45px;
  .label-value-container {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 24px 13px;
    background: white;
    .label-value-wrap {
      width: 100%;
      padding-top: 10px;
      border-top: 1px solid #f5f6f7;
      font-size: 14px;
      .label {
        color: #818181;
      }
      .value {
        color: #2C2C2C;
      }
      ::v-deep.van-cell {
        padding: 7px 0 0;
      }
    }
  }
  .title {
    width: 100%;
    height: 43px;
    box-sizing: border-box;
    padding-left: 24px;
    line-height: 43px;
    font-size: 14px;
    color: #818181;
  }
  .upload-wrap {
    padding-top: 12px;
  }
  .btn-wrap {
    width: 100%;
    // padding-top: 184px;
    position: fixed;
    left: 0;
    bottom: 0;
  }
}
</style>