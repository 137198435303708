<template>
  <div class="growers-detail-page">
    <Header type="add" path="/bill/bigGrowersAdd" class="header" />
    <Card :info="info" :clickable="false"></Card>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import Card from './components/detailCard.vue'

export default {
  components: {
    Header,
    Card
  },
  data() {
    return {
      info: {}
    }
  },
  activated() {
    if (this.$route.params.info) {
      this.info = this.$route.params.info
    }
  }
}
</script>

<style lang="scss" scoped>
.growers-detail-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  .header {
    margin-bottom: 12px;
  }
}
</style>