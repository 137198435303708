<template>
  <div class="container">
    <Card class="card" @cardClick="handleClick">
      <div class="content-wrap">
        <div class="label-value-container" v-for="(item, index) in labelValueList" :key="index">
          <div class="label">{{item.label}}</div>
          <div class="value">{{item.value}}</div>
        </div>
        <div class="label mb-6">备注</div>
        <div class="value mb-6">{{!info.remark || info.remark.length === 0 ? '无' : info.remark}}</div>
        <div class="label mb-7" v-if="info.images && info.images.length > 0">图片</div>
        <ImageViewer :images="info.fundPhoto.split(',') || []" :upload="false" />
      </div>
    </Card>
  </div>
</template>

<script>
import Card from '@/components/common/card/whiteCard.vue'
import ImageViewer from '@/components/common/imageViewer/index.vue'

export default {
  components: {
    Card,
    ImageViewer,
  },
  props: {
    info: {
      type: Object,
      default: ()=>{
        return {
          creator: '',
          date: '',
          type: '',
          money: '',
          remark: '',
          fundPhoto: []
        }
      }
    },
    clickable: {
      type: Boolean,
      default: true
    },
    map: {
      type: Object,
      default: ()=> {
        return {}
      }
    },
    mapField: {
      type: String,
      default: ''
    } 
  },  
  data() {
    return {

    }
  },
  computed: {
    labelValueList() {
      let labels = ['创建人','日期','账款类型','账款金额']
      let fields = ['createName', 'fundDate', 'fundType', 'fundNum']
      let result = []
      labels.map((val,index)=>{
        result.push({
          label: val,
          value: fields[index] === this.mapField ? this.map[this.info[fields[index]]] : this.info[fields[index]] || '--'
        })
      })
      return result
    }
  },
  methods: {
    handleClick() {
      if (this.clickable){
        this.$emit('click', this.info)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  .card {
    .content-wrap {
      padding-top: 14px;
      padding-bottom: 16px;
      font-size: 12px;
      text-align: left;
      .label-value-container {
        width: 100%;
        height: 17px;
        line-height: 17px;
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        &:nth-child(n+3) .value{
          margin-left: 12px;
        }
      }
    }
    .label {
      width: 48px;
      height: 17px;
      color: #818181;
    }
    .value {
      height: 17px;
      color: #2C2C2C;
    }
    .mb-6 {
      margin-bottom: 6px;
    }
    .mb-7 {
      margin-bottom: 7px;
    }
  }
}
</style>