<template>
  <div class="terminal-add-page">
    <Header type="add" />
    <Form ref="formA" :rows="rowsFirst" v-model="formFirst" />
    <div class="label-value-container">
      <div class="label-value-wrap">
        <div class="label">地址</div>
        <Field placeholder="请输入地址" v-model="address" />
      </div>
    </div>
    <div class="title">账款信息</div>
    <Form ref="formB" :rows="rowsSecond" v-model="formSecond" />
    <div class="upload-wrap">
      <ImgUpload label="凭证拍照" :required="true" v-model="images" />
    </div>
    <div class="btn-wrap">
      <Button size="full" @click="submit">提交</Button>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import Form from '@/components/common/form/index.vue'
import { Field } from 'vant'
import ImgUpload from '@/components/common/imgUploadCard/index.vue'
import Button from '@/components/common/button/CustomButton.vue'
import dayjs from 'dayjs'
import { submitBill } from '@/api/bill'
import { getDealerId } from '@/utils/user'

export default {
  components: {
    Header,
    Form,
    Field,
    ImgUpload,
    Button,

  },
  data() {
    return {
      rowsFirst: [
        {label: '终端联系人', key: 'contact', type: 'input'},
        {label: '联系电话', key: 'phone', type: 'input', maxlength: 11},
      ],
      rowsSecond: [
        {label: '日期', key: 'date', type: 'date', required: true},
        {label: '账目类型', key: 'type', type: 'radio', required: true, data: [
          { key: "欠款", value: "欠款" },
          { key: "还款", value: "还款" },
        ]},
        {label: '金额/元', key: 'money', type: 'input', required: true},
      ],
      images: [],
      address: '',
      formFirst: {
        date: new Date(),
      },
      formSecond: {},
      terminalId: '',
      terminalType: ''
    }
  },
  activated() {
    this.terminalId = this.$route.query.terminalId || ''
    this.terminalType = this.$route.query.terminalType || ''
    this.images = [];
    this.address = this.$route.query.address || '';
    this.formFirst = {
      date: new Date(),
      contact: this.$route.query.contactPerson || '',
      phone: this.$route.query.contactTel || '',
    };
    this.formSecond = {
      date: new Date(),
      type: '欠款'
    }
  },
  methods: {
    async submit() {
      if (!this.$refs.formA.validate() || !this.$refs.formB.validate()) {
        return
      }
      if (this.images.length === 0) {
        this.$toast('请上传图片')
        return
      }
      try {
        await submitBill({
          "terminalId": this.terminalId || "4a3b41f030774f6985c664d63fe43b69",
          "terminalType": this.terminalType || "1",
          "fundDate": dayjs(this.formSecond.date).format('YYYY-MM-DD'),
          "fundType": this.formSecond.type === '欠款' ? 1 : 2,
          "fundNum": this.formSecond.money,
          "fundPhoto":  this.images.join(','),
          "dealerId": getDealerId(),
        })
        this.$toast("提交成功");
        setTimeout(()=>{
          this.$router.back();
        }, 1000)
      }
      catch (err) {
        this.$toast(err.message);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.terminal-add-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  text-align: left;
  .label-value-container {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 24px 13px;
    background: white;
    .label-value-wrap {
      width: 100%;
      padding-top: 10px;
      border-top: 1px solid #f5f6f7;
      font-size: 14px;
      .label {
        color: #818181;
      }
      .value {
        color: #2C2C2C;
      }
      ::v-deep.van-cell {
        padding: 7px 0 0;
      }
    }
  }
  .title {
    width: 100%;
    height: 43px;
    box-sizing: border-box;
    padding-left: 24px;
    line-height: 43px;
    font-size: 14px;
    color: #818181;
  }
  .upload-wrap {
    padding-top: 12px;
    margin-bottom: 12px;
  }
  .btn-wrap {
    width: 100%;
    padding-top: 47px;
  }
}
</style>