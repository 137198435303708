<template>
  <div class="terminal-manage-page">
    <Header type="add" :path="`/bill/terminalAdd?terminalId=${terminalId}&terminalType=${terminalType}&contactPerson=${contactPerson}&contactTel=${contactTel}&address=${address}`" />
    <Card v-for="(item, index) in list" :key="index" :info="item" @click="handleClickCard" :map="map" mapField="fundType" style="margin-bottom: 12px;"></Card>
    <InfiniteLoading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        暂无更多
      </span>
    </InfiniteLoading>
  </div>
</template>

<script>
import Header from '@/components/header/headerWithNavigation/index.vue'
import Card from './components/detailCard.vue'
import InfiniteLoading from 'vue-infinite-loading'
import { fetchTerminalBillManageList } from '@/api/bill'
import { getDealerId } from '@/utils/user'

export default {
  components: {
    Header,
    Card,
    InfiniteLoading,
  },
  data() {
    return {
      list: [],
      pageNo: 1,
      pageSize: 5,
      total: 0,
      map: {
        '1': '欠款',
        '2': '还款'
      },
      terminalId: '',
      terminalType: '',
      address: '',
      contactPerson:'',
      contactTel: ''
    }
  },
  beforeMount() {
    // this.terminalId = this.$route.params.terminalId || ''
    // this.terminalType = this.$route.params.terminalType || ''
  },
  activated() {
    // if (this.terminalId !== this.$route.params.terminalId || this.terminalType !== this.$route.params.terminalType) {
      this.terminalId = this.$route.params.terminalId || ''
      this.terminalType = this.$route.params.terminalType || ''
      this.address = this.$route.query.address || '';
      this.contactPerson = this.$route.query.contactPerson || '';
      this.contactTel = this.$route.query.contactTel || '';
      this.resetList()
    // }
  },
  methods: {
    resetList() {
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.pageNo = 1;
      this.total = 0;
    },
    async infiniteHandler($state) {
      await this.fetchList();
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },

    async fetchList() {
      let response = []
      let data = {
        "dealerId": getDealerId(),
        "terminalId": this.terminalId,
        "terminalType": this.terminalType,
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
      }
      response = await fetchTerminalBillManageList(data)
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },

    handleClickCard(info) {
      this.$router.push({
        name: 'BillTerminalDetail',
        query: {info},
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.terminal-manage-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
}
.no-more {
  font-size: 14px;
  color: #323232;
}
</style>